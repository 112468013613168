#kontakt{
	background: #f1f1f1;

	#first-section{
		background: #f1f1f1;
		padding-top: 60px;
		padding-bottom: 80px;

		h1{
			color: #383838;
			font-size: 40px;
			padding: 0px;
			margin:0;
			font-family: $antonregular;
			text-transform: uppercase;
			position: relative;
			z-index: 55;
			margin-bottom: 60px;
			text-align: center;

			&:after{
				content: "";
				width: 120px;
				height: 5px;
				bottom: -30px;
				left: 50%;
				margin-left: -60px;
				position: absolute;
				z-index: 55;
				background: $main;
			}
		}

		p{
			color: #383838;
			font-size: 18px;
			font-family: $ubunturegular;
			text-align: center;
			line-height: 150%;
		}

			.person{
				margin-top: 30px;
				padding: 0 10%;

				img{
					width: 100%;
					max-width: 100%;
					height: auto;
					border-radius: 100%;
					margin-bottom: 20px;
				}

				p{
					color: #383838;
					font-size: 20px;
					font-family: $ubunturegular;
					text-align: center;
					line-height: 150%;

					&.namn{
						font-family: $ubuntubold;
						color: #383838;
					}

					a{
						color: $main;
					}
				}
			}

		.fel-section{
			margin-top: 70px;
			padding-top: 70px;
			border-top: 4px solid #383838;

			h2{
				color: #383838;
				font-size: 36px;
				font-family: $antonregular;
				text-transform: uppercase;
				text-align: center;
				margin-bottom: 30px;
			}

			p{
				color: #383838;
				font-size: 18px;
				font-family: $ubunturegular;
				text-align: center;
				line-height: 150%;
			}
		}			
	}

	.map{
		height: auto;
		margin: 0;
		padding: 0;
		width: 100%;
		max-width: 100%;
	}	
}