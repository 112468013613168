//Colors
$main: #004f9f;
$second: #419fce;

//Fonts
$antonregular: antonregular, sans-serif;
$helveticaInserat: helveticaInserat, sans-serif;
$ubunturegular: ubunturegular, sans-serif;
$ubuntubold: ubuntubold, sans-serif;

@font-face {
    font-family: antonregular;
    src: url("assets/fonts/Anton-Regular.ttf");
}

@font-face {
    font-family: helveticaInserat;
    src: url("assets/fonts/HELVETI4.ttf");
}

@font-face {
    font-family: ubunturegular;
    src: url("assets/fonts/Ubuntu-Regular.ttf");
}

@font-face {
    font-family: ubuntubold;
    src: url("assets/fonts/Ubuntu-Bold.ttf");
}