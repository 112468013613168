h1 {

}

p {

}

.button{
	text-transform: uppercase;
	font-size: 16px;
	font-family: $ubuntubold;
	padding: 15px;
	display: inline-block;

	background: $main;
	color: #ffffff;

	&:hover{
		color: #ffffff;
	}
}