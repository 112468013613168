body{
	@media only screen and (max-width : 992px) {
		word-break: break-word;
    	-webkit-hyphens: auto;
       -moz-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
	}
}

#offert{
		text-align: center;
		padding-top: 80px;
		padding-bottom: 80px;
		position:relative;

		background-image: url('assets/images/image-offert.jpg');
		background-size: cover;
		background-position: center;

		.overlay{
			background-color: rgba(0,79,159,0.50);
			height: 100%;
			width: 100%;
			position: absolute;
			left:0;
			top:0;
		}

		.offert-content{
			background: #ffffff;
			padding: 40px 80px;
			border-bottom: 8px solid $main;
			-webkit-box-shadow: 2px 2px 16px 0px rgba(0,0,0,0.2);
			-moz-box-shadow: 2px 2px 16px 0px rgba(0,0,0,0.2);
			box-shadow: 2px 2px 16px 0px rgba(0,0,0,0.2);

			@media only screen and (max-width : 768px) {
		    	padding: 20px 0px;
		    }
		}

		h3{
			color: #212121;
			font-size: 36px;
			padding: 0px;
			margin:0;
			font-family: $antonregular;
			text-transform: uppercase;
			position: relative;
			margin-bottom: 40px;

			&:after{
				content: "";
				width: 90px;
				height: 5px;
				bottom: -20px;
				left: 50%;
				margin-left: -45px;
				position: absolute;
				background: $main;
			}
		}

		p{
			color: #212121;
			font-size: 18px;
			font-family: $ubunturegular;
			position: relative;
		}

		form{
			padding: 0 20px;
			margin-top: 40px;
		}

		.wpcf7-text, .wpcf7-select, .wpcf7-textarea{
			border:none;
			border-bottom: 3px solid $main;
			background: none;
			margin-left: 0px;
			min-width: 100%;
			max-width: 100%;
			color: #6a6a6a;
			-webkit-appearance: none;
			border-radius: 0;
			padding:15px;
			font-family: $ubunturegular;
		}

		.wpcf7-textarea{
			margin-left: 0;
			min-width: 100%;
			max-width:100%;
			-webkit-appearance: none;
			border-radius: 0;
		}

		.wpcf7-select{
			border-radius: 0;
			-webkit-appearance: none;
			background: url(assets/images/icons/arrow_down.png) no-repeat;
			background-size: 20px;
			background-position: 98%;
			min-width: 100%;
			max-width: 100%;
		}

		.wpcf7-submit{
			-webkit-appearance: none;
			margin-top: 20px;
			line-height: 1;
			border: none;

			&:hover{
				text-decoration: underline;
			}
		}

		.ajax-loader{
			display: none;
		}

		input:focus, select:focus, textarea:focus{
			outline:none;
			-webkit-box-shadow: none;
		  	box-shadow: none;
		}

		input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
			color: #6a6a6a !important;
			font-family: $ubunturegular;
		}
		 
		input:-moz-placeholder, textarea:-moz-placeholder { /* Firefox 18- */
			color: #6a6a6a !important;  
			font-family: $ubunturegular;
		}
		 
		input::-moz-placeholder, textarea::-moz-placeholder {  /* Firefox 19+ */
			color: #6a6a6a !important;  
			font-family: $ubunturegular;
		}
		 
		input:-ms-input-placeholder, textarea:-ms-input-placeholder {  
			color: #6a6a6a !important;  
			font-family: $ubunturegular;
		}
}