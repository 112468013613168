footer{
	padding-top: 50px;
	padding-bottom: 30px;
	background: #00050d;

	h5{
		color: #ffffff;
		font-size: 18px;
		padding: 0px;
		margin-top: 20px;
		font-family: $antonregular;
		text-transform: uppercase;
	}

	p{
		color: #ffffff;
		font-size: 16px;
		font-family: $ubunturegular;
		line-height: 27px;
	}

	.footer-line{
		width: 90px;
		height: 2px;
		display: block;
		background: #2b2b2b;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	@media only screen and (min-width : 1200px) {
		.kontor::after{
			content:'';
			width: 2px;
			height: 60px;
			display: block;
			background: #2b2b2b;
			position: absolute;
			right: 15px;
			top: 10px;
		}
	}

	img{
		display: inline-block;
		height: 25px;
		width: auto;
		margin-right: 10px;
		margin-top: 5px;
	}

	.bottom-footer{
		margin-top: 30px;

		p{
			text-align: center;
			color: #ffffff;
			font-size: 16px;
			font-family: $ubunturegular;
			margin: 0;

			a{
				color: #ffffff;
			}
		}
	}
}