#omoss{
	background: #f1f1f1;

	#first-text{
		background: #f1f1f1;
		padding-top: 60px;
		padding-bottom: 80px;

		h1{
			color: #383838;
			font-size: 40px;
			padding: 0px;
			margin:0;
			font-family: $antonregular;
			text-transform: uppercase;
			position: relative;
			z-index: 55;
			margin-bottom: 60px;
			text-align: center;

			&:after{
				content: "";
				width: 120px;
				height: 5px;
				bottom: -30px;
				left: 50%;
				margin-left: -60px;
				position: absolute;
				z-index: 55;
				background: $main;
			}
		}

		p{
			color: #383838;
			font-size: 18px;
			font-family: $ubunturegular;
			text-align: justify;
			line-height: 150%;
		}
	}

	#big-picture{
		background: $main;
		padding-top: 75px;
		padding-bottom: 250px;
		position:relative;

		.overlay{
			background-color: rgba(0,79,159,0.50);
			height: 100%;
			width: 100%;
			position: absolute;
			left:0;
			top:0;
		}

		h2{
			color: #ffffff;
			font-size: 20px;
			font-family: $ubuntubold;
			text-align: left;
			margin-bottom: 20px;
			margin-top: 50px;
		}

		p{
			color: #ffffff;
			font-size: 18px;
			font-family: $ubunturegular;
			text-align: left;
			line-height: 200%;
		}
	}



	#personal{
		position:relative;
		top:-200px;
		margin-bottom: -200px;

		.personal-content{
			background: #ffffff;
			padding: 40px 80px;
			border-bottom: 8px solid $main;

			-webkit-box-shadow: 2px 2px 16px 0px rgba(0,0,0,0.2);
			-moz-box-shadow: 2px 2px 16px 0px rgba(0,0,0,0.2);
			box-shadow: 2px 2px 16px 0px rgba(0,0,0,0.2);

			@media only screen and (max-width : 768px) {
		    	padding: 20px 0px;
		   	}

		  	h3{
				color: #383838;
				font-size: 24px;
				font-family: $antonregular;
				text-align: center;
				text-transform: uppercase;
				margin-bottom: 30px;
			}

			.person{
				margin-top: 30px;
				padding: 0 10%;

				img{
					width: 100%;
					max-width: 100%;
					height: auto;
					border-radius: 100%;
					margin-bottom: 20px;
				}

				p{
					color: $main;
					font-size: 20px;
					font-family: $ubunturegular;
					text-align: center;
					line-height: 150%;

					&.namn{
						font-family: $ubuntubold;
						color: #383838;
					}
				}
			}	
		}
		
	}

	#leverantorer{
		background: #f1f1f1;
		padding-top: 80px;
		padding-bottom: 120px;
		text-align: center;

		h2{
			color: #383838;
			font-size: 36px;
			font-family: $antonregular;
			text-transform: uppercase;
			text-align: center;
			margin-bottom: 20px;
		}

		p{
			color: #383838;
			font-size: 20px;
			font-family: $ubunturegular;
			text-align: center;
			margin-bottom: 30px;
		}

		img{
			width: auto;
			max-width: 100%;
			height: 100px;
			display: inline-block;
			margin-top: 30px;
			-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  			filter: grayscale(100%);

  			-webkit-transition: all 0.2s; /* Safari */
	    	transition: all 0.2s;

  			&:hover{
  				-webkit-filter: none; /* Safari 6.0 - 9.0 */
  				filter: none;
  			}
		}
	}
}