#tjanster{
	background: #f1f1f1;

	#big-picture{
		height: 450px;
		position:relative;

		.overlay{
			background-color: rgba(0,79,159,0.50);
			height: 100%;
			width: 100%;
			position: absolute;
			left:0;
			top:0;
		}
	}

	#tjanster-content{
		position:relative;
		top:-130px;
		margin-bottom: -130px;

		.tjanster-content-box{
			background: #ffffff;
			padding: 40px 80px;
			text-align: center;

			-webkit-box-shadow: 2px 2px 16px 0px rgba(0,0,0,0.2);
			-moz-box-shadow: 2px 2px 16px 0px rgba(0,0,0,0.2);
			box-shadow: 2px 2px 16px 0px rgba(0,0,0,0.2);

			@media only screen and (max-width : 768px) {
		    	padding: 20px 10%;
		   	}

		  	h1{
				color: #383838;
				font-size: 40px;
				font-family: $antonregular;
				text-align: center;
				text-transform: uppercase;
				margin-bottom: 30px;
				position: relative;
				margin-bottom: 40px;

				&:after{
					content: "";
					width: 90px;
					height: 5px;
					bottom: -20px;
					left: 50%;
					margin-left: -45px;
					position: absolute;
					background: $main;
				}
			}

			h2{
				color: #383838;
				font-size: 20px;
				font-family: $ubuntubold;
				text-align: left;
				margin-bottom: 30px;
			}

			p{
				color: #383838;
				font-size: 18px;
				font-family: $ubunturegular;
				text-align: left;
				margin-bottom: 50px;
				line-height: 200%;

				&.text-center{
					text-align: center !important;
				}
			}	
		}
	}

	#other-services{
		padding-top: 50px;
		padding-bottom: 50px;
		background: $main;
		text-align: center;
		margin-top: 60px;

		h3{
			color: #ffffff;
			font-size: 30px;
			font-family: $antonregular;
			text-transform: uppercase;
			text-align: center;
			margin-bottom: 30px;
		}

		ul{
			list-style: none;
			margin:0;
			padding:0;

			li{
				margin-top: 30px;

				a{
					color: #ffffff;
					font-size: 20px;
					font-family: $ubunturegular;
					text-transform: uppercase;
					text-align: center;
					position:relative;
					padding-right: 50px;

					&:after{
						content:'';
						height: 20px;
						width: 20px;
						top:0px;
						right: 0px;
						position:absolute;
						background: url(assets/images/icons/arrow-right.png) no-repeat;
						background-size: contain;
					}
				}
			}
		}
	}
}