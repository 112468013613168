#menu{
    height: 100px; 
	width: 100%;
	padding: 0 30px;
	background: #ffffff;
	border-bottom: 7px solid $main;

	&.start{
		background: transparent;
		border-bottom: none;
		position: absolute;
		top: 0;
		z-index: 99999999;
	}

	.navbar-header img{
		padding: 20px 0 20px 0;
		height:100px;
		width: auto;
	}

	.navbar-default{
		background-color:transparent;
		border-color: transparent;
		margin-bottom:0px;
		border: 0;
	}

	.navbar-collapse{
		border:none;
	}

	ul.nav	{
		margin: 0px 0 0 0;
		position: absolute;
		z-index: 9999999;
		left: 50%;
		transform: translateX(-50%);
		top: 25px;

		&.navbar-nav>li {

			float: left;
			>a	{
				font-family: $antonregular;
				font-size: 20px;
				color: #212121;
				text-transform: uppercase;
				border-bottom: 4px solid transparent;

				margin: 0px 10px 0 10px;
				padding: 10px 10px;
				text-align: left;

				-webkit-transition: all 0.2s; /* Safari */
	    		transition: all 0.2s;

	    		@media only screen and (max-width : 1300px) {
	    			margin: 0px 5px 0 5px;
					padding: 10px 5px;
	    		}
			}

			a:hover, &.current-menu-item>a{
				border-bottom: 4px solid $main;
			}

			&.menu-item-has-children:after{
				content:'';
				height: 10px;
				width: 10px;
				top:15px;
				right: 5px;
				position:absolute;
				background: url(assets/images/icons/arrow_down.png) no-repeat;
				background-size: contain;

				@media only screen and (max-width : 1300px) {
	    			display: none;
	    		}
			}
		}

		li:hover>ul{
			z-index: 9999999;
			position: absolute;
			display: block;
		}

		ul{
			display: none;
			list-style: none;
			margin: 0px;
			float: left;
			background-color: #ffffff;
			padding:0;
			border-top: 2px solid #f4f4f4;

			>li{
				float: left;
				padding: 10px 25px;
				border-bottom: 2px solid #f4f4f4;

				>a{
					font-family: $antonregular;
					font-size: 12px;
					color: #2a2c2d;
					text-transform: uppercase;
					min-width: 200px;
					display: block;
				}

				a:hover, &.current-menu-item>a{
					border-bottom: none !important;
				}
			}
		}
	}

	.header-phone{
		position: absolute;
		right: 15px;
		top: 25px;

		p{
			font-family: $antonregular;
			font-size: 20px;
			color: #212121;
			text-transform: uppercase;

			padding: 5px 10px;
			text-align: right;

			.number{
				color: $main;
			}
		}
	}
}


/* #Responsive meny
================================================== */

.toggle-id-1 {
	color: $main;
	font-size: 38px;
	height: 50px;
	width: 50px;
	position: absolute;
	top: 10px;
	display: none;
	right: 1px;
}

.close-menu {
	color: white;
	font-size: 34px !important;
	float: right;
	margin-top: 15px;
	margin-right: 20px;
}

.responsive-menu-container {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 70px;
	overflow-y: scroll;
	background: $main;
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
	left: 0;
	z-index: 999999999;
	opacity: 1;
	padding-left: 30px;
	padding-top:30px;
	display:none;

	&::-webkit-scrollbar { 
		width: 0 !important 
	}

	.responsiveMenu {
		list-style-type: none;
		margin-left: 0px;
		padding-left: 0px;

		li {
			a{
				color: #ffffff;
				display: block;
				font-size: 24px;
				padding: 10px;
				text-align: left;
				font-family: $antonregular;
				text-transform: uppercase;
			}

			a:hover, &.current-menu-item>a { 
				text-decoration: none;
			}

			ul{
				list-style: none;
				padding: 0;
				padding-left: 30px;

				li > a{
					font-size: 16px;
				}
			}
		}

		._mi {
			border-right: 0px;
		}
	}

	.header-phone-mobile{
		margin-top: 30px;

		p{
			font-family: $antonregular;
			font-size: 16px;
			color: #ffffff;
			text-transform: uppercase;

			padding: 10px 10px;
			text-align: left;

			.number{
				color: #ffffff;
			}
		}
	}
}


.responsiveMenuImage {
	padding: 15px 0 15px 20px;
	height: 70px;
}


/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {
	.toggle-id-1 {
		display: inline-block;
	}

	.navbar-header {
		width: 100%;
	}

	body{
		padding-top:70px;
	}

	#menu {
		background: #ffffff !important;
		position: fixed !important;
		top: 0;
		height: 70px;
		z-index: 999999;
		border-bottom: 5px solid $main !important;

		.navbar-nav {
			display: none;
		}

		.navbar-header img {
			margin: 0px;
			height: 70px !important;
			padding: 15px 0 15px 0 !important;
		}
	}

	.header-phone{
		display: none;
	}
}

/* ================================================== */


