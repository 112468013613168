.jumbotron{
	height: 100vh;
	margin: 0;
	padding: 0;
	width: 100%;
	text-align: center;
	display: table;

	.container{
		display: table-cell;
		vertical-align: middle;
	}

	h4{
		color: #4d4d4d;
		font-size: 30px;
		padding: 0px;
		margin:0;
		font-family: $ubuntubold;
		text-transform: uppercase;

		@media only screen and (max-width : 768px) {
	    	font-size: 25px;
	    }
	}

	h2{
		color: $main;
		font-size: 100px;
		padding: 0px;
		margin:0;
		font-family: $helveticaInserat;
		text-transform: uppercase;
		font-weight: 900;

		@media only screen and (max-width : 992px) {
	    	font-size: 80px;
	    }

	    @media only screen and (max-width : 768px) {
	    	font-size: 40px;
	    }
	}

	p{
		color: #212121;
		font-size: 18px;
		font-family: $ubunturegular;
		margin-top: 30px;
		margin-bottom: 40px;
	}
}