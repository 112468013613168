/*
Theme Name: En hemsida av Pixl
Theme URI: http://www.pixl.se
Description: En hemsida av Pixl
Version: 1.0
Author: Pixl Webbyrå Sweden AB
Author URI: http://www.pixl.se
*/

@import 'partials/variables';
@import 'partials/elements';
@import 'partials/header';
@import 'partials/jumbotron';
@import 'partials/startpage';
@import 'partials/omoss';
@import 'partials/tjanster';
@import 'partials/kontakt';
@import 'partials/index';
@import 'partials/theme';
@import 'partials/footer';