#startpage{

	#three-boxes{
		text-align: center;
		padding-top: 110px;
		padding-bottom: 110px;
		background-image: url('assets/images/background-pattern.png');
		background-position: top left;
  		background-repeat: repeat;

		.box{
			height: 500px;
			display: table;
			-webkit-box-shadow: 2px 2px 16px 0px rgba(0,0,0,0.2);
			-moz-box-shadow: 2px 2px 16px 0px rgba(0,0,0,0.2);
			box-shadow: 2px 2px 16px 0px rgba(0,0,0,0.2);

			.overlay-one{
				background-color: rgba(0,79,159,0.80);
				height: 100%;
				width: 100%;
				position: absolute;
				left:0;
				top:0;
			}

			.overlay-two{
				background-color: rgba(0,41,82,0.80);
				height: 100%;
				width: 100%;
				position: absolute;
				left:0;
				top:0;
			}

			.overlay-three{
				background-color: rgba(33,33,33,0.80);
				height: 100%;
				width: 100%;
				position: absolute;
				left:0;
				top:0;
			}
		}

		.box-content{
			display: table-cell;
			vertical-align: middle;
		}

		h3{
			color: #ffffff;
			font-size: 36px;
			padding: 0px;
			margin:0;
			font-family: $antonregular;
			text-transform: uppercase;
			position: relative;
			margin-bottom: 40px;

			&:after{
				content: "";
				width: 90px;
				height: 5px;
				bottom: -20px;
				left: 50%;
				margin-left: -45px;
				position: absolute;
				background: #ffffff;
			}
		}

		p{
			color: #ffffff;
			font-size: 18px;
			font-family: $ubunturegular;
			padding: 0 10%;
			position: relative;
		}

		a{
			color: #ffffff;
			font-family: $antonregular;
			font-size: 18px;
			text-transform: uppercase;
			margin-top: 30px;
			display: block;
			position: relative;

			&:hover{
				color: #ffffff;
			}
		}
	}
}