#nyheter{
	background: #f1f1f1;

	padding-top: 60px;
	padding-bottom: 90px;
	text-align: center;

		h1{
			color: #212121;
			font-size: 40px;
			padding: 0px;
			margin:0;
			font-family: $antonregular;
			text-transform: uppercase;
			position: relative;
			z-index: 55;
			margin-bottom: 90px;
			text-align: center;

			&:after{
				content: "";
				width: 120px;
				height: 5px;
				bottom: -20px;
				left: 50%;
				margin-left: -60px;
				position: absolute;
				z-index: 55;
				background: $main;
			}
		}

		.grid{
			height: 100% !important; 
		}

		.alm-load-more-btn.done{
		   display: none;
		}

		.grid-sizer, .grid-item{
			width: 33.333333%;
			margin-bottom: 10px;
			padding: 0 10px;

			@media only screen and (max-width : 992px) {
				width: 100%;
			}
		}

		.nyhet{
			background: #ffffff;

			img{
				width: 100%;
				max-width: 100%;
				height: auto;
			}

			.text-box{
				padding: 30px 20px 0px 20px;

				h2{
					color: #212121;
					font-size: 27px;
					font-family: $ubuntubold;
					text-align: center;
					line-height: 150%;
					margin-top: 0;
					word-break: break-word;
			    	-webkit-hyphens: auto;
			       -moz-hyphens: auto;
			        -ms-hyphens: auto;
			            hyphens: auto;
				}

				p{
					color: #212121;
					font-size: 18px;
					font-family: $ubunturegular;
					text-align: center;
					line-height: 150%;

					&.date{
						font-size: 16px;
						border-top: 1px solid #f1f1f1;
						margin-top: 30px;
						text-align: left;
						padding: 10px 0;
					}

					img{
						display: inline-block;
						height: 20px;
						width: auto;
						margin-right: 5px;
					}
				}
			}
		}

		.button{
			margin-top: 180px;

			-webkit-box-shadow: 2px 2px 16px 0px rgba(0,0,0,0.2);
			-moz-box-shadow: 2px 2px 16px 0px rgba(0,0,0,0.2);
			box-shadow: 2px 2px 16px 0px rgba(0,0,0,0.2);
		}
}